<template>
  <v-layout column>
    <FieldHeader
      class="px-6"
      v-if="!hideHeader"
      :field="field"
      :patient="patient"
      :session.sync="session"
      :owner="owner"
      :hideCollapseBtn="hideCollapseBtn"
      :collapsed="hideBody"
      @history="onHistory"
      @collapse="onCollapse"
      :status="status"
      :name="name"
      autosave
    ></FieldHeader>
    <v-layout v-if="!disabled && !hideBody" column>
      <v-layout column align-center>
        <PregnancyStatusMenu
          v-if="data.length == 0"
          @select="onAddPregnancy"
          label="Dodaj ciąże"
          :rightMargin="$vuetify.breakpoint.smAndDown ? '8px' : '0px'"
          includeInProgress
        ></PregnancyStatusMenu>
        <PregnancyDelegate
          class="mb-6"
          v-for="(value, index) in data"
          :key="index"
          :value.sync="data[index]"
          :pregnancyIndex="data.length - index"
          @delete="() => onDeletePregnancy(index)"
        >
        </PregnancyDelegate>
        <v-layout :class="'justify-start full-width'"> </v-layout>
      </v-layout>
      <v-layout
        v-if="data.length != 0 && !disabled && !hideBody"
        class="mx-4 mb-4"
      >
        <PregnancyStatusMenu
          :class="['order-last']"
          @select="onAddAnother"
          :label="'Dodaj kolejną ciąże'"
          :includeInProgress="!isInProgress"
          :rightMargin="$vuetify.breakpoint.smAndDown ? '8px' : '0px'"
        ></PregnancyStatusMenu>
      </v-layout>
    </v-layout>
    <DisabledFieldWrapper
      v-else-if="!hideBody"
      :field="field"
      :hideEdit="hideEdit"
      :session="session"
      :owner="owner"
      @edit="onEdit"
      @append="onAppend"
      class="mx-6"
    >
      <v-layout column class="mt-3">
        <PregnancyDelegate
          class="mb-6"
          v-for="(value, index) in data"
          :key="index"
          :value.sync="data[index]"
          :pregnancyIndex="data.length - index"
          disabled
        >
        </PregnancyDelegate>
      </v-layout>
    </DisabledFieldWrapper>
  </v-layout>
</template>

<script>
import ModificationMixin from "@/mixins/ModificationMixin";

const IN_PROGRESS = 0;

export default {
  mixins: [ModificationMixin],
  props: {
    field: {},
    session: {},
    patient: {},
    owner: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    hideCollapseBtn: {
      type: Boolean,
      default: false,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    hideBody: {
      type: Boolean,
      default: false,
    },
    syncId: {
      type: String,
      default: "patientCard",
    },
    clientOnlyPredefined: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  computed: {
    data: {
      get() {
        if (!this.field.data) {
          this.$set(this.field, "data", []);
        }
        return this.field.data;
      },
      set(value) {
        this.$set(this.field, "data", value);
      },
    },
    dataJson: {
      get() {
        return JSON.stringify(this.data);
      },
    },
    isInProgress() {
      return (
        this.data.length > 0 &&
        this.data[0].childrens.length > 0 &&
        this.data[0].childrens[0].kind == IN_PROGRESS
      );
    },
  },
  components: {
    FieldHeader: () =>
      import("@/components/delegates/patientcard/fields/FieldHeader"),
    PregnancyDelegate: () =>
      import("@/components/delegates/patientcard/fields/PregnancyDelegate"),
    DisabledFieldWrapper: () =>
      import("@/components/delegates/patientcard/fields/DisabledFieldWrapper"),
    PregnancyStatusMenu: () =>
      import("@/components/menus/patientcard/PregnancyStatusMenu"),
  },
  methods: {
    onEdit() {
      this.$emit("edit");
    },
    onHistory() {
      this.$emit("history");
    },
    onPredefined(data) {
      this.$emit("predefined", data);
    },
    onPredefinedInline(data) {
      data.forEach((record) => {
        this.text += `<p>${record}</p>`;
      });
    },
    onCollapse() {
      this.$emit("collapse");
    },
    onAppend(data) {
      this.$emit("append", data);
    },
    onAddAnother(kind) {
      this.onAddPregnancy(kind);
    },
    onAddPregnancy(kind) {
      const pregnancies = this.data;
      if (this.isInProgress) {
        this.data = [
          pregnancies[0],
          {
            childrens: [
              {
                pregnancy_type: 0,
                date: "",
                week: "",
                mass: "",
                length: "",
                kind: kind,
                reason: "",
              },
            ],
          },
          ...pregnancies.slice(1),
        ];
      } else {
        this.data = [
          {
            childrens: [
              {
                pregnancy_type: 0,
                date: "",
                week: "",
                mass: "",
                length: "",
                kind: kind,
                reason: "",
              },
            ],
          },
          ...pregnancies,
        ];
      }
    },
    onFinishPregnancy(kind) {
      this.$set(this.data[0].childrens[0], "kind", kind);
    },
    onDeletePregnancy(index) {
      this.data.splice(index, 1);
    },
  },
  mounted() {
    if (!this.disabled && this.autosave) {
      this.startWatching("dataJson");
    }
  },
};
</script>

<style lang="scss" scoped>
.pch-text-wrapper {
  border-radius: 15px;
  background-color: white;
}
.pch-text {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.pch-text ::v-deep p {
  margin-bottom: 0 !important;
}
.default-shadow {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1) !important;
}
</style>